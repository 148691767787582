import * as client_hooks from '../../../src/hooks.client.js';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46')
];

export const server_loads = [0,3];

export const dictionary = {
		"/": [~6],
		"/connect/substrate/[network]/[token]": [~8],
		"/ethereum/eth": [9],
		"/ethereum/eth/my-stakings": [10],
		"/ethereum/eth/staking": [11],
		"/ethereum/eth/unstake": [12],
		"/ethereum/eth/withdraw": [13],
		"/ethereum/pol": [14,[2]],
		"/ethereum/pol/claim": [15,[2]],
		"/ethereum/pol/move-stake": [16,[2]],
		"/ethereum/pol/my-stakings": [17,[2]],
		"/ethereum/pol/restake": [18,[2]],
		"/ethereum/pol/staking": [19,[2]],
		"/ethereum/pol/unstake": [20,[2]],
		"/ethereum/pol/withdraw": [21,[2]],
		"/klever/kdas": [31,[3]],
		"/klever/[token]": [~22,[3]],
		"/klever/[token]/claim": [~23,[3]],
		"/klever/[token]/my-stakings": [~24,[3]],
		"/klever/[token]/staking": [~25,[3]],
		"/klever/[token]/undelegate": [~26,[3]],
		"/klever/[token]/unstake": [~27,[3]],
		"/klever/[token]/validators": [~28,[3]],
		"/klever/[token]/validators/delegate": [~29,[4]],
		"/klever/[token]/withdraw": [~30,[3]],
		"/substrate/[network]/[token]": [~32,[5]],
		"/substrate/[network]/[token]/claim": [~33,[5]],
		"/substrate/[network]/[token]/my-stakings": [~34,[5]],
		"/substrate/[network]/[token]/staking": [~35,[5]],
		"/substrate/[network]/[token]/unstake": [~36,[5]],
		"/substrate/[network]/[token]/withdraw": [~37,[5]],
		"/tron/trx": [38],
		"/tron/trx/claim": [39],
		"/tron/trx/my-stakings": [40],
		"/tron/trx/staking": [41],
		"/tron/trx/unstake-legacy": [43],
		"/tron/trx/unstake": [42],
		"/tron/trx/vote": [44],
		"/tron/trx/vote/[address]": [45],
		"/tron/trx/withdraw": [46],
		"/[network]/[token]/result": [~7]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';